<template>
  <validation-observer
    v-slot="{ invalid, dirty }"
    tag="form"
    @submit.prevent="saveVatNumber"
  >
    <v-card>
      <v-card-title>
        Update VAT Number
        <v-spacer />
        <v-btn icon @click="$emit('cancel')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-alert type="info" text>
          Registered businesses outside of Germany but inside of Europe can enter their European VAT number to remove tax charges from future bills if elligible.
        </v-alert>
        <v-alert v-if="error" type="error" outlined>
          {{ error }}
        </v-alert>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="European VAT Number"
          >
            <v-text-field
              v-model="eu_vat"
              outlined
              label="European VAT Number"
              required
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="primary"
          block
          x-large
          type="submit"
          :disabled="!dirty || invalid || eu_vat === initialValue"
          :loading="loading"
        >
          Save VAT Number
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UpdateVatNumber',
  data: () => ({
    error: null,
    taxIds: [],
    eu_vat: '',
    initialValue: '',
    loading: false
  }),
  computed: {
    ...mapGetters(['organization'])
  },
  watch: {
    taxIds: {
      handler(taxIds) {
        if (taxIds && taxIds.length) {
          this.eu_vat = taxIds[0].value
          this.initialValue = taxIds[0].value
        }
      },
      immediate: true
    }
  },
  async beforeMount() {
    const { stripeCustomerId } = this.organization
    if (!stripeCustomerId) {
      return
    }
    const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/vat-number`, {
      params: {
        stripeCustomerId
      }
    })
    this.taxIds = data
  },
  methods: {
    async saveVatNumber() {
      const { stripeCustomerId } = this.organization
      try {
        this.loading = true
        const { data } = await this.$axios.post(`${this.$apiUrl}/stripe/vat-number`, {
          stripeCustomerId,
          eu_vat: this.eu_vat
        })
        if (data.error) {
          return this.handleError(data.error.message)
        }
        this.$emit('done')
      } catch (error) {
        this.handleError(error)
      }
    },
    handleError(message) {
      this.loading = false
      this.error = message
    }
  }
}
</script>
